import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationAdd() {
  // Use toast
  const toast = useToast()

  const postCamperApplication = queryParams => axios
    .post('/auth/apply-camper', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Camper Application was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting Camper Application',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postStaffApplication = queryParams => axios
    .post('/auth/apply-staff', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Staff Application was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting Staff Application',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchProgram = ID => axios
    .get(`/auth/programs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchForm = ID => axios
    .get(`/auth/forms/${ID}`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const uploadFiles = (ID, file) => axios
    .post(`/auth/file/upload/${ID}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => response.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error uploading file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  return {
    postCamperApplication,
    postStaffApplication,
    fetchProgram,
    fetchForm,
    uploadFiles,
  }
}
