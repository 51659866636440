<template>
  <b-list-group-item
    tag="div"
    class="d-flex justify-content-start"
  >
    <b-col
      md="3"
      xl="3"
    >
      <b-card-text>
        <span class="w-100 bv-no-focus-ring col-form-label pt-0">
          Card
        </span>
        <h5 class="mt-2">
          Credit Card
        </h5>
      </b-card-text>
    </b-col>

    <b-col
      md="4"
      xl="4"
    >
      <b-form-group
        label="Card Number"
      >
        <ValidationProvider
          #default="{ errors }"
          name="Card Number"
          rules="cardNumber|required"
        >
          <b-form-input
            v-model="card.number"
            v-mask="['#### #### #### ####','#### #### #### ###']"
            autocomplete="cc-number"
            size="lg"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </b-col>
    <b-col
      md="2"
      xl="2"
    >
      <b-form-group
        label="Exp Date"
      >
        <ValidationProvider
          #default="{ errors }"
          name="Exp Date"
          rules="cardDate|required"
        >
          <b-form-input
            v-model="card.date"
            v-mask="'##/##'"
            size="lg"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </b-col>
    <b-col
      md="2"
      xl="2"
    >
      <b-form-group
        label="CVC"
      >
        <ValidationProvider
          #default="{ errors }"
          name="CVC"
          rules="required"
        >
          <b-form-input
            v-model="card.cvc"
            v-mask="['###', '####']"
            size="lg"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>
    </b-col>
  </b-list-group-item>
</template>

<script>
import { extend, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BCol, BListGroupItem, BFormGroup, BFormInput, BCardText,
} from 'bootstrap-vue'
import { isValid, isExpirationDateValid } from 'creditcard.js'

extend('cardNumber', value => isValid(value))
extend('cardDate', value => isExpirationDateValid(value.split('/')[0], value.split('/')[1]))

export default {
  components: {
    BCol,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BCardText,

    ValidationProvider,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
    }
  },
}
</script>
