<template>

  <b-row>

    <!-- Left Col: Card -->
    <b-col
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions"
    >

      <b-badge
        variant="primary"
        class="d-block p-75 mb-1"
      >
        <span class="mb-0 text-white">Submission #</span>
      </b-badge>

      <b-card
        no-body
      >

        <b-card-header class="d-flex justify-content-between align-items-center">
          <h5>
            {{ camperStudent(1) }} Profile
          </h5>
        </b-card-header>

        <b-card-body>
          <ul class="list-unstyled">
            <li>
              <span class="align-middle">Name: </span>
            </li>
            <li class="my-25">
              <span class="align-middle">DOB: </span>
            </li>
            <li class="my-25">
              <span class="align-middle">Gender: </span>
            </li>
            <li class="mt-25">
              <span class="align-middle">Parent ID:
                <b-badge
                  variant="light-primary"
                >#</b-badge>
              </span>
            </li>
          </ul>
        </b-card-body>

      </b-card>

    </b-col>

    <!-- Right Col: -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <ValidationObserver
          ref="applicationForm"
        >
          <b-form
            enctype="multipart/form-data"
          >
            <div
              v-for="(item, index) in fieldsList"
              v-show="!item.hidden"
              :key="item.frontID"
            >
              <ApplicationAddRow
                :key="item.frontID"
                :fields-data="item"
                :field-index="index"
                @influence="influence"
              />
            </div>

            <b-list-group-item
              v-if="fieldsList"
              tag="div"
              class="d-flex justify-content-start"
            >
              <b-col
                md="3"
                xl="3"
              >
                <b-card-text>
                  <span class="w-100 bv-no-focus-ring col-form-label pt-0">
                    Field
                  </span>
                  <h5 class="mt-2">
                    E-mail
                  </h5>
                </b-card-text>
              </b-col>

              <b-col
                md="7"
                xl="7"
              >
                <b-form-group
                  label="Result"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="E-mail"
                    rules="required"
                  >
                    <b-form-input
                      v-model="email"
                      type="email"
                      size="lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-list-group-item>

            <div v-if="fieldsList">
              <ApplicationAddCreditCardRow
                :card="card"
              />
            </div>

            <div
              class="card-footer"
            >
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="isButtonDisabled"
                @click="saveForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-primary"
                @click="cancelForm"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-card>

    </b-col>

  </b-row>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BBadge, BButton, BForm, BListGroupItem, BFormGroup, BFormInput, BCardText,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/applications/applicationsStoreModule'
import useApplicationAdd from '@/views/admin/applications/application-add/useApplicationAdd'
import router from '@/router'

import ApplicationAddRow from '@/views/admin/applications/application-add/ApplicationAddRow.vue'
import ApplicationAddCreditCardRow from '@/views/admin/applications/application-add/ApplicationAddCreditCardRow.vue'

export default {
  components: {
    BForm,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BCardText,

    ValidationProvider,
    ValidationObserver,

    ApplicationAddRow,
    ApplicationAddCreditCardRow,
  },
  directives: {
    Ripple,
  },
  mixins: [formBuilderPreparations],
  data() {
    return {
      groupDetail: {},
      setProgram: null,
      type: null,
      fieldsList: [],
      fileField: false,
      file: '',
      email: '',
      card: {
        number: '',
        date: '',
        cvc: '',
      },
      isButtonDisabled: false,
      required,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-applications'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchProgram,
      fetchForm,
      postCamperApplication,
      postStaffApplication,
      uploadFiles,
    } = useApplicationAdd()

    return {
      fetchProgram,
      fetchForm,
      postCamperApplication,
      postStaffApplication,
      uploadFiles,
    }
  },
  watch: {
    setProgram(val) {
      if (!val) return

      this.fetchForm(val.form_id)
        .then(response => {
          this.fieldsList = response.fields.map(row => {
            if (row.type === 'file_upload') this.fileField = true
            return {
              id: row.id,
              value: this.designateValue(row.type, row.default),
              label: row.label,
              name: row.name,
              options: this.designateOptions(row.type, row.options),
              type: this.transformField(row.type),
              required: row.required,
              hidden: this.checkHiddenAttr(row.conditions.filter(item => item.action === 'Show')),
              fieldInfluence: response.conditions.filter(item => item.parent_field === row.id),
              conditions: response.conditions,
            }
          })
        })
    },
  },
  async created() {
    this.type = router.currentRoute.params.type
    const programID = router.currentRoute.params.id
    if (programID) {
      await this.fetchProgram(programID).then(response => {
        this.setProgram = response
        this.setProgram.form_id = response.forms.filter(form => form.type === this.type)[0]?.id
      })
    }
  },
  methods: {
    checkHiddenAttr(obj) {
      return obj.length > 0
    },
    cancelForm() {
      this.$router.push({ name: 'admin-applications' })
    },
    uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      return this.uploadFiles(`application-files-${new Date().getTime()}`, formData)
    },
    influence(relatedFields, value) {
      relatedFields.forEach(id => {
        const out = this.fieldsList.findIndex(item => id[1] === item.id)
        if (out >= 0) {
          const conditions = this.fieldsList[out].conditions.find(item => item.id === id[0])

          // eslint-disable-next-line default-case
          switch (conditions.operator) {
            case '==':
              if (conditions.value === value || (Array.isArray(value) && value.length === 1 && value.includes(conditions.value))) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '!=':
              if (conditions.value !== value || (Array.isArray(value) && value.length === 1 && !value.includes(conditions.value))) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '==contains':
              if (value.includes(conditions.value)) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '!=contains':
              if (!value.includes(conditions.value)) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '==empty':
              if (!value) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '!=empty':
              if (value) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '<':
              if (+conditions.value < +value) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
            case '>':
              if (+conditions.value > +value) {
                this.fieldsList[out].hidden = conditions.action === 'Hide'
              } else {
                this.fieldsList[out].hidden = conditions.action !== 'Hide'
              }
              break
          }
        }
      })
    },
    async saveForm() {
      this.$refs.applicationForm.validate().then(success => {
        if (success) {
          let fileFieldsCount = 0
          const queryParams = {
            form_id: this.setProgram.form_id,
            program_id: router.currentRoute.params.id,
            fields: [],
            form_data: {},
          }
          const filesLinks = []
          const filesNames = []

          if (this.fileField) {
            const files = this.fieldsList.filter(form => form.type === 'file_upload')

            files.forEach(file => {
              file.value.forEach(val => {
                filesLinks.push(this.uploadFile(val))
              })
            })
          }

          Promise.all(filesLinks)
            .then(responses => responses.forEach(
              response => {
                filesNames.push(response.file_path)
              },
            ))
            .finally(() => {
              this.fieldsList.forEach(row => {
                if (row.type === 'file_upload') {
                  queryParams.fields.push({
                    field_id: row.id,
                    files: filesNames.slice(fileFieldsCount, row.value.length),
                  })
                  queryParams.form_data[row.name] = filesNames.slice(fileFieldsCount, row.value.length)
                  fileFieldsCount += 1
                } else {
                  queryParams.fields.push({
                    field_id: row.id,
                    result: row.value,
                  })
                  queryParams.form_data[row.name] = row.value
                }
              })

              if (['Camper', 'Student'].includes(this.type)) {
                queryParams.card = {
                  number: this.card.number,
                  exp_month: this.card.date.split('/')[0],
                  exp_year: this.card.date.split('/')[1],
                  cvc: this.card.cvc,
                }
                queryParams.form_data.email = this.email
                this.isButtonDisabled = true
                this.postCamperApplication(queryParams).then(() => {
                  this.isButtonDisabled = false
                  this.$router.push({ name: 'admin-applications' })
                })
              } else {
                this.postStaffApplication(queryParams)
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
